import {HTTP} from '../utils/axios'

class InfoModel extends HTTP {
    getCommunities() {
        return this.request({
            url: '/api/xcx/info/getCommunitys',
            method: "GET"
        })
    }

    getActivities(data) {
        return this.request({
            url: '/api/xcx/info',
            method: "GET",
            data: data
        })
    }

    ///api/v1/activity/updateActivityByCondition

    //是否更新社区
    updateActivityByCondition(data) {
        return this.request({
            url: '/api/v1/info/updateInfoByCondition',
            method: "PATCH",
            data: data
        })
    }

    postActivity(data) {
        return this.request({
            url: '/api/v1/info',
            method: "POST",
            data: data
        })
    }

    updateActivity(data) {
        return this.request({
            url: '/api/v1/info',
            method: "PATCH",
            data: data
        })
    }

    deleteActivity(data) {
        return this.request({
            url: '/api/v1/info',
            method: "DELETE",
            data: data
        })
    }
}

export { InfoModel}